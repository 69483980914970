import * as React from "react"
import PropTypes from "prop-types"
import Logo from "../assets/svg/logo.svg"
import Qr from "../assets/svg/qr-code.svg"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div className="card">
        <h1 className="logo">
          <Logo />
        </h1>
        <div className="contact">
          <div>
            <span className="contact-name">Maria Thiloba</span>
            <small className="contact-title">Driving Instructor</small>
          </div>
          <div className="contact-phone">
            <a href="tel:+6141 325 6655">041 325 6655</a>
          </div>
        </div>
      </div>
      <a href="https://qrco.de/bciOu2" className="qr">
        <Qr />
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
